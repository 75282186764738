import React, { useState, useEffect } from 'react'
import './GamePageStyles.scss'
import IBottomDrawer from '../../components/IBottomDrawer/IBottomDrawer'
import AnimateButton from '../../components/AnimateButton'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

import { useUser } from '../../services'
import { useTelegram } from '../../hooks/useTelegram'

const GameScene = () => {
    const [gameModal, setGameModal] = useState(false)
    const [distValue, setDistValue] = useState('000')

    const { useMiningBonus } = useUser()
    const miningMutate = useMiningBonus()
    const { user } = useTelegram()

    const handleClickButton = () => {
        miningMutate.mutate(
            { chatId: user?.id, mine: distValue },
            {
                onSuccess: (res) => {
                    setGameModal(false)
                    reloadPage()
                },
            }
        )
    }

    const reloadPage = () => {
        window.location.reload()
    }

    useEffect(() => {
        const handleMessage = (event) => {
            console.log('event', event.origin)

            if (event.origin !== 'https://game1.chinahouse.su') return

            console.log('show', event.data.show)
            console.log('event.data', event.data)

            if (event.data.show) {
                setDistValue(event.data.distValue * 2)
                setGameModal(true)
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    return (
        <div className="GameScene">
            <Header fixed={true} reload={true} />
            <IBottomDrawer
                height={'50vh'}
                open={gameModal}
                onOpen={(e) => setGameModal(e)}
                maskClosable={false}
                // headIcon={HeaderIcon}
                title={`Поздравляю вы вийграли ${distValue} бонус`}
                content={
                    <div className="EarnModal-form">
                        <AnimateButton
                            fixed={false}
                            onClick={() => handleClickButton()}
                            loading={miningMutate.isLoading}
                            width={'100%'}
                            left="0"
                        >
                            Получить
                        </AnimateButton>
                    </div>
                }
            />
            <iframe
                id="gameiframeId"
                src="https://game1.chinahouse.su"
            ></iframe>
            <Footer />
        </div>
    )
}

export default GameScene
