import { useLocation, useNavigate } from 'react-router-dom'

import './Footer.scss'
import { HomeOutlined, DashboardOutlined } from '@ant-design/icons'
import WinnerIcon from '../../assets/images/winners-svgrepo-com.svg'
import GameIcon from '../../assets/images/211668_controller_b_game_icon.png'

export default function Footer({ badgeStatusPath = '' }) {
    const location = useLocation()
    const navigate = useNavigate()

    const handleTabClick = (path) => {
        navigate(path)
    }

    const menus = [
        {
            id: 1,
            title: 'Главная',
            link: '/',
            icon: () => <HomeOutlined />,
            badge: false,
        },
        {
            id: 2,
            title: 'Победители',
            link: '/winners',
            icon: () => (
                <img src={WinnerIcon} width={14} height={14} alt="WinnerIcon" />
            ),
            badge: false,
        },
        {
            id: 3,
            title: 'Задачи',
            link: '/earn',
            icon: () => <DashboardOutlined />,
            badge: true,
        },
        // {
        //     id: 4,
        //     title: 'Game',
        //     link: '/game',
        //     icon: () => (
        //         <img src={GameIcon} width={14} height={14} alt="WinnerIcon" />
        //     ),
        //     badge: true,
        // },
        {
            id: 5,
            title: 'Профиль',
            link: '/profile',
            icon: () => <HomeOutlined />,
            badge: false,
        },
    ]

    return (
        <div className="Footer">
            <div className="Footer-wrapper">
                <div className="Footer-wrapper-tabbar">
                    {menus.map((menu) => {
                        return (
                            <div
                                className={`tabbar-list ${
                                    location.pathname === menu.link
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() => handleTabClick(menu.link)}
                                key={menu.id}
                            >
                                {menu.icon()}
                                {badgeStatusPath === menu.link ||
                                    (menu.badge && (
                                        <div className="badgeTab" />
                                    ))}
                                <span>{menu.title}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
