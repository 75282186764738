import { Alert, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './Home.scss'

import MoneyIcon from '../../assets/images/downloadedImag.png'
import LogoIcon from '../../assets/images/logo-v1.jpg'

const HomePage = () => {
    const navigate = useNavigate()

    const prizes = [
        {
            count: 1,
            price: 3000,
        },
        {
            count: 2,
            price: 2000,
        },
        {
            count: 3,
            price: 1250,
        },
        {
            count: 4,
            price: 1000,
        },
        {
            count: 5,
            price: 750,
        },
        {
            count: 6,
            price: 750,
        },
        {
            count: 7,
            price: 500,
        },
        {
            count: 8,
            price: 300,
        },
        {
            count: 9,
            price: 250,
        },
        {
            count: 10,
            price: 200,
        },
    ]

    return (
        <div className="HomePage">
            <Header />
            <div className="container">
                <div className="HomePage-wrapper">
                    <Alert
                        message="🔥 Скоро добавим новую игру 'Миллионер'! 🔥"
                        description="Приглашай друзей и получай бонусы прямо сейчас, пока розыгрыш не остановлен! Призы ждут тебя уже в следующем обновлении 🎁💸"
                        type="success"
                        style={{
                            marginBottom: 10,
                        }}
                        closable
                    />
                    <div className="welcome-user">
                        <div
                            className="welcome-user-head"
                            style={{
                                background: 'red',
                                height: 120,
                            }}
                        >
                            <img src={LogoIcon} alt="logo" />
                        </div>
                        <h1>🔥 Добро пожаловать в конкурс от ChinaHouse! 🔥</h1>{' '}
                        <span>
                            {' '}
                            Вы только что выиграли{' '}
                            <span
                                style={{
                                    color: 'var(--violet)',
                                    fontWeight: 'bold',
                                }}
                            >
                                100 000
                            </span>{' '}
                            бонусов!{' '}
                        </span>{' '}
                        <p>
                            {' '}
                            Хотите ещё больше? Приглашайте друзей и получайте за
                            каждого <strong>+300 000 бонусов!</strong> Чем
                            больше друзей — тем больше бонусов!{' '}
                        </p>{' '}
                        <p>
                            {' '}
                            <strong>Каждую пятницу в 17:00 по МСК</strong> мы
                            разыгрываем призы для топ-10 игроков недели. Не
                            упустите свой шанс сорвать куш! Собирайте друзей,
                            играйте вместе и становитесь победителем! Чем больше
                            играете — тем больше выигрываете!{' '}
                        </p>
                        <Button
                            className="btn-start"
                            block
                            onClick={() => navigate('/profile')}
                        >
                            Начать
                        </Button>
                    </div>

                    {prizes.map((item) => {
                        return (
                            <div
                                className="welcome-user"
                                key={item.count + item.price}
                            >
                                <div className="welcome-user-head">
                                    <img src={MoneyIcon} alt="logo" />
                                </div>
                                <h1>
                                    {item.count} место{' '}
                                    <span style={{ color: 'red' }}>
                                        {item.price} ₽
                                    </span>
                                </h1>
                            </div>
                        )
                    })}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default HomePage
