import './WinnersPageStyles.scss'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useTelegram } from '../../hooks/useTelegram'
import { useTask } from '../../services'

export default function WinnersPage() {
    const { user } = useTelegram()
    const { useGetWinners } = useTask()
    const { data } = useGetWinners(user?.id)

    const winnersList = data?.lists || []
    const topWinners = data?.tops || []

    return (
        <div className="WinnersPage">
            <Header fixedAnimated={true} />
            <div className="WinnersPage-wrapper">
                <div className="WinnersPage-wrapper-head">
                    <div className="user2">
                        <span>2</span>
                        <span>
                            {topWinners[1]?.DrawUser?.username?.substr(0, 10) ||
                                'Место'}
                        </span>
                    </div>
                    <div className="user1">
                        <span>1</span>
                        <span>
                            {topWinners[0]?.DrawUser.username?.substr(0, 10) ||
                                'Место'}
                        </span>
                    </div>
                    <div className="user3">
                        <span>3</span>
                        <span>
                            {topWinners[2]?.DrawUser.username?.substr(0, 10) ||
                                'Место'}
                        </span>
                    </div>
                </div>
                <div className="hr-win" />
                <div className="container">
                    <div className="winners">
                        <h2>Победители {winnersList?.[0]?.week} недели:</h2>
                        <ul>
                            {winnersList?.map((el, index) => {
                                return (
                                    <li key={el.id}>
                                        <span>
                                            {el?.DrawUser?.username?.substr(
                                                0,
                                                20
                                            ) ||
                                                el?.DrawUser?.firstName?.substr(
                                                    0,
                                                    20
                                                )}
                                        </span>
                                        <span>{index + 1} Место</span>
                                        <span className="winn">
                                            {el?.win} ₽
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
